var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_c("div", _vm._g({}, on), [_vm._t("default")], 2)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          class: `shepherd-assignment-intro-${this.bundleData.bid}`,
          attrs: { flat: "" },
        },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v(_vm._s(_vm.bundleData.long_id))]
                ),
                _c("v-spacer"),
                _vm.bundleData.bundle_type_name ===
                  _vm.distributionReasons.ASSIGNMENT && !_vm.isSpaceAdmin
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mr-1",
                                            attrs: { icon: "", small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.tour.start()
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("help_outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              388847052
                            ),
                          },
                          [_c("span", [_vm._v("Show tour")])]
                        ),
                        !_vm.isAssignmentOverdue(
                          _vm.bundleData.allow_handins_until
                        ) && !_vm.isSpaceAdmin
                          ? _c(
                              "span",
                              {
                                class: `shepherd-handin-button-${_vm.bundleData.bid}`,
                              },
                              [
                                _c("TheSnapshotAssignmentSubmitDialog", {
                                  attrs: { bundleData: _vm.bundleData },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                class: `caption shepherd-handin-button-${_vm.bundleData.bid}`,
                              },
                              [
                                _vm._v(
                                  "Deadline has passed, no further hand-ins allowed"
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.bundleData.bundle_type_name ===
                  _vm.distributionReasons.ASSIGNMENT && _vm.isSpaceAdmin
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  disabled:
                                                    !_vm.isAssignmentOverdue(
                                                      _vm.bundleData
                                                        .allow_handins_until
                                                    ),
                                                  elevation: "0",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startGrading()
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [_vm._v("grading")]
                                              ),
                                              _vm._v(" Grade "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1669138317
                            ),
                          },
                          [
                            _vm.isAssignmentOverdue(
                              _vm.bundleData.allow_handins_until
                            )
                              ? _c("span", [
                                  _vm._v(
                                    "Assignment deadline has passed, grading can now be started."
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "Assignment grading can be started only after the due date has passed."
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                {
                  class: `mt-5 shepherd-assignment-description-${_vm.bundleData.bid}`,
                  attrs: { type: "info", text: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-center flex-row w-100",
                    },
                    [
                      _c("div", [
                        _vm.bundleData.description
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.bundleData.description) + " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.bundleData.allow_handins_until
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-center my-2" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "info" },
                                  },
                                  [_vm._v("alarm")]
                                ),
                                _vm._v(
                                  " Due: " +
                                    _vm._s(
                                      _vm._f("dateTimeToHuman")(
                                        _vm.bundleData.allow_handins_until
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.bundleData.creation_timestamp
                          ? _c("div", { staticClass: "mt-1 caption" }, [
                              _c("span", [_vm._v("Created")]),
                              _vm._v(
                                " : " +
                                  _vm._s(
                                    _vm._f("dateTimeToHuman")(
                                      _vm.bundleData.creation_timestamp
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("v-spacer"),
                      _vm.isSpaceAdmin &&
                      _vm.bundleData.bundle_type_name ===
                        _vm.distributionReasons.ASSIGNMENT
                        ? _c("TheBundleEditDialog", {
                            attrs: { bundleData: _vm.bundleData },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dialogactivator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [_vm._v("edit")]
                                          ),
                                          _vm._v(" Edit "),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              679146836
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Details")]),
                  _vm.bundleData.bundle_type_name ===
                    _vm.distributionReasons.ASSIGNMENT && _vm.isSpaceAdmin
                    ? _c(
                        "v-tab",
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("grading"),
                          ]),
                          _vm._v(" Grades "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _vm.bundleData.bundle_type_name ===
                        _vm.distributionReasons.ASSIGNMENT &&
                      _vm.grade &&
                      !_vm.isSpaceAdmin
                        ? _c(
                            "div",
                            { staticClass: "mt-7 mb-5 d-flex" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "secondary--text subtitle-1 font-weight-bold",
                                  },
                                  [_vm._v("Grade")]
                                ),
                                _vm._v(" Your grade for this assignment is "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.grade))]
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("visibility")]
                                                ),
                                                _vm._v(" Review Corrections "),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1803662218
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.applications, function (a) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: a.aid,
                                          attrs: {
                                            to: {
                                              name: "app-open",
                                              params: {
                                                oid: _vm.$route.params.oid,
                                                sid: _vm.$route.params.sid,
                                                iid: _vm.$route.params.iid,
                                                snid: _vm.$route.params.snid,
                                                aid: a.aid,
                                              },
                                              query: {
                                                handback_bid:
                                                  _vm.bundleData.bid,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(a.long_id)),
                                          ]),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.bundleData.bundle_type_name ===
                            _vm.distributionReasons.ASSIGNMENT &&
                          !_vm.bundleData.handbacks_visible &&
                          _vm.isAssignmentOverdue(
                            _vm.bundleData.allow_handins_until
                          )
                        ? _c("div", { staticClass: "mt-7 mb-5" }, [
                            _vm._v(
                              " This assignment is currently being graded and hand-backs / grades are not yet visible. "
                            ),
                          ])
                        : _vm.bundleData.bundle_type_name ===
                            _vm.distributionReasons.ASSIGNMENT &&
                          _vm.bundleData.handbacks_visible &&
                          _vm.isAssignmentOverdue(
                            _vm.bundleData.allow_handins_until
                          ) &&
                          !_vm.grade &&
                          !_vm.isSpaceAdmin
                        ? _c("div", { staticClass: "mt-7 mb-5" }, [
                            _vm._v(
                              " You haven't received a grade for this assignment. "
                            ),
                          ])
                        : _vm.bundleData.bundle_type_name ===
                            _vm.distributionReasons.ASSIGNMENT &&
                          !_vm.isAssignmentOverdue(
                            _vm.bundleData.allow_handins_until
                          ) &&
                          !_vm.isSpaceAdmin
                        ? _c("div", { staticClass: "mt-7 mb-5" }, [
                            _vm._v(
                              " The assignment deadline has not yet passed, you can continue to hand-in solutions using the Hand-In button above. "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "v-expansion-panels",
                        {
                          staticClass: "w-100",
                          attrs: { flat: "", multiple: "" },
                          model: {
                            value: _vm.panel,
                            callback: function ($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel",
                          },
                        },
                        [
                          _vm.bundleData.objects.files.length
                            ? _c(
                                "v-expansion-panel",
                                {
                                  class: `shepherd-assignment-files-${_vm.bundleData.bid}`,
                                },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "Files (" +
                                              _vm._s(
                                                _vm.bundleData.objects.files
                                                  .length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-group",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { color: "secondary" },
                                            },
                                            _vm._l(
                                              _vm.bundleData.objects.files,
                                              function (file) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: file,
                                                    staticClass: "pa-0",
                                                    staticStyle: {
                                                      "max-height": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-avatar",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-file-outline"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass:
                                                              "subtitle-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                file.slice(6)
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.bundleData.bundle_type_name ===
                            _vm.distributionReasons.ASSIGNMENT &&
                          !_vm.isSpaceAdmin
                            ? _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "My Submissions (" +
                                              _vm._s(
                                                _vm.bundleData.handins.length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-end" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                outlined: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$store.dispatch(
                                                    "spaceStore/fetchSpaceBundles",
                                                    _vm.$route.params.sid
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("refresh")]
                                              ),
                                              _vm._v(" Refresh "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.bundleData.handins &&
                                      _vm.bundleData.handins.length
                                        ? _c(
                                            "v-list",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: { color: "secondary" },
                                                },
                                                _vm._l(
                                                  _vm.bundleData.handins,
                                                  function (submission, idx) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: idx,
                                                        staticClass: "pa-0",
                                                        staticStyle: {
                                                          "max-height": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-avatar",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "assignment_turned_in"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      submission.long_id
                                                                    ) +
                                                                    " (submitted on: " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateTimeToHuman"
                                                                      )(
                                                                        submission.handin_timestamp
                                                                      )
                                                                    ) +
                                                                    ") "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-alert",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { type: "info" },
                                            },
                                            [
                                              _vm._v(
                                                " No handins yet, please click the refresh button above to fetch them if you recently handed one in. "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          _vm.bundleData.objects.tables.length
                            ? _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "Tables (" +
                                              _vm._s(
                                                _vm.bundleData.objects.tables
                                                  .length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-group",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { color: "secondary" },
                                            },
                                            _vm._l(
                                              _vm.bundleData.objects.tables,
                                              function (table) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: table,
                                                    staticClass: "pa-0",
                                                    staticStyle: {
                                                      "max-height": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-avatar",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-table-large"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass:
                                                              "secondary--text subtitle-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(table)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.bundleData.objects.applications.length && false
                            ? _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "Applications (" +
                                              _vm._s(
                                                _vm.bundleData.objects
                                                  .applications.length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-group",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { color: "secondary" },
                                            },
                                            _vm._l(
                                              _vm.bundleData.objects
                                                .applications,
                                              function (application) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: application,
                                                    staticClass: "pa-0",
                                                    staticStyle: {
                                                      "max-height": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-avatar",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "desktop_windows"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass:
                                                              "secondary--text subtitle-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                application
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.bundleData.bundle_type_name ===
                  _vm.distributionReasons.ASSIGNMENT
                    ? _c(
                        "v-tab-item",
                        [
                          _c("the-snapshot-assignment-grades", {
                            attrs: {
                              bid: _vm.bundleData.bid,
                              overdue: _vm.isAssignmentOverdue(
                                _vm.bundleData.allow_handins_until
                              ),
                              bundleData: _vm.bundleData,
                            },
                            on: { closeModal: _vm.closeModal },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }